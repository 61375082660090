
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPagePageLayout} from 'layouts/CmsPagePageLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPagePageLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`I siti web della `}<em parentName="p">{`next-generation`}</em>{`, la prossima generazione, sono `}<a parentName="p" {...{
        "href": "static-websites"
      }}>{`siti web statici`}</a>{`.`}</p>
    <p>{`Sono ottimi per realizzare sia `}<strong parentName="p">{`semplici `}<em parentName="strong">{`siti vetrina`}</em></strong>{`, sia `}<strong parentName="p">{`portali più complessi, come `}<em parentName="strong">{`blog`}</em>{` e `}<em parentName="strong">{`negozi online`}</em></strong>{`.`}</p>
    <p>{`Non avere paura, sarai in grado di scrivere contenuti in modo semplice. Sono presenti I classici strumenti di amministrazione a cui sei abituato, proprio come nei siti web dinamici.`}</p>
    <p>{`Vorrei evitare fraintendimenti: `}<strong parentName="p">{`un sito web statico può includere animazioni e interagire dinamicamente con i visitatori secondo le tue esigenze`}</strong>{`. Le parole `}<em parentName="p">{`statico`}</em>{` e `}<em parentName="p">{`dinamico`}</em>{` si riferiscono a come un sito web è costruito nelle sue fondamenta; non descrivono le caratteristiche dei suoi contenuti.`}</p>
    <p>{`Vediamo ora perché un sito web statico potrebbe essere la scelta migliore.`}</p>
    <h2>{`1. Velocità`}</h2>
    <p>{`Avere un sito veloce fa la differenza. I tuoi potenziali clienti apriranno il risultato successivo su Google con conseguenze negative per il tuo brand quando una pagina non carica abbastanza in fretta.`}</p>
    <p>{`I siti web statici sono i più veloci. Le loro pagine si caricano in modo quasi istantaneo e la navigazione tra di esse non subisce ritardi.`}</p>
    <h2>{`2. Sicurezza`}</h2>
    <p>{`Un sito web statico è più sicuro. Le probabilità di scovare bug sono minori. In più, gli hacker non avranno spazio per attaccarti e non avranno accesso ai tuoi dati sensibili.`}</p>
    <h2>{`3. Costi ridotti`}</h2>
    <p>{`Gestire e manutenere un sito web statico costa meno perché la sua struttura è semplice e snella. Disponiamo anche di soluzioni convenienti per la pubblicazione.`}</p>
    <h2>{`4. Immagini ottimizzate`}</h2>
    <p>{`I tuoi visitatori vedranno foto sempre perfette dai loro smartphone, tablet e PC (desktop). Questo perché i nostri siti web statici ne ottimizzano la risoluzione grafica automaticamente per tutti i dispositivi.`}</p>
    <h2>{`5. Versioni`}</h2>
    <p>{`Hai cancellato pagine per errore? Non è più un problema perché potrai ripristinare tutto. Non sei contento delle ultime modifiche che hai fatto? Potrai tornare indietro e ricominciare daccapo.`}</p>
    <p>{`Un sito web statico mantiene uno storico di tutta la sua evoluzione nel tempo.`}</p>
    <h2>{`6. Manutenzione più semplice`}</h2>
    <p>{`Un sito web statico è più facile da manutenere perché per funzionare ha bisogno solamente di un server web.`}</p>
    <p>{`Contrariamente, un sito web dinamico ha bisogno di un server web, di un linguaggio che generi le pagine a ogni visualizzazione e di un database dove sono salvati i contenuti.`}</p>
    <h2>{`7. Modularità`}</h2>
    <p>{`Agganciare `}<em parentName="p">{`database`}</em>{` per salvare informazioni raccolte dai visitatori. Accettare pagamenti in puro stile `}<em parentName="p">{`e-commerce`}</em>{`. Interrogare `}<em parentName="p">{`servizi web`}</em>{` esterni. Connettersi con `}<em parentName="p">{`software di terze parti`}</em>{` come il tuo gestionale.`}</p>
    <p>{`Un sito web statico è in grado di svolgere un’infinità di compiti complessi.`}</p>
    <h2>{`8. Scalabilità`}</h2>
    <p>{`Un sito web statico non perderà mai la sua velocità caratteristica qualora i tuoi visitatori aumentino. Il cloud lo ottimizzerà automaticamente in base alle variazioni di traffico.`}</p>
    <p>{`Vuoi saperne di più? Scopri `}<a parentName="p" {...{
        "href": "static-websites"
      }}>{`come funziona un sito web statico`}</a>{` oppure `}<a parentName="p" {...{
        "href": "contact"
      }}>{`contattami`}</a>{`.`}</p>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    